@import "../../../variables";
@import "bootstrap/scss/mixins/breakpoints";
@import "./content404__typography";

.content-404 {
    padding: 32px 16px;

    @include media-breakpoint-up(lg) {
        padding: 64px 0;
    }

    &-title {
        margin-bottom: 16px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 24px;
        }
    }

    &-sub-title {
        margin-bottom: 24px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 32px;
        }
    }

    &-description {
        margin-bottom: 32px;


        @include media-breakpoint-up(lg) {
            margin: 0 auto 56px;
            max-width: 970px;
        }
    }

    &-search {
        align-items: center;
        display: flex;
        flex-wrap: nowrap;
        margin: 0 auto 32px;
        max-width: 340px;
        border-radius: 4px;

        @include media-breakpoint-up(md) {
            max-width: 615px;
        }

        @include media-breakpoint-up(lg) {
            margin: 0 auto 56px;
            max-width: 730px;
        }
    }

    &-search-field {
        border: 0;
        flex-grow: 1;
        min-height: 50px;
        outline: none;
        padding-left: 14px;
    }

    &-search-submit {
        align-self: stretch;
        border: 0;
        padding: 0 14px;
    }
}
