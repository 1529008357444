@import "../../../variables";
@import "bootstrap/scss/mixins/breakpoints";

.content-404 {
    &-title {
        color: var(--text-secondary);
        font-size: var(--text-3xl);
        font-weight: bold;
        line-height: 48px;
        text-align: center;

        @include media-breakpoint-up(lg) {
            font-size: var(--text-4xl);
            line-height: 72px;
        }
    }

    &-sub-title {
        color: var(--text-secondary);
        font-size: var(--text-lg);
        font-weight: bold;
        line-height: 24px;
        text-align: center;

        @include media-breakpoint-up(lg) {
            font-size: var(--text-xl);
            line-height: 32px;
        }
    }

    &-description {
        color: var(--text-secondary);
        font-size: var(--text-sm);
        font-weight: normal;
        line-height: 20px;
        text-align: center;

        @include media-breakpoint-up(lg) {
            font-size: var(--text-base);
            line-height: 24px;
        }
    }

    &-search {
        border: 1px solid var(--border-primary);
    }

    &-search-field {
        font-size: var(--text-sm);
        font-weight: normal;
        line-height: 20px;
        /* stylelint-disable-next-line */
        &,
        &[placeholder] {
            color: var(--text-secondary);
        }
    }

    &-search-submit {
        align-self: stretch;
        background-color: var(--text-primary);
        color: var(--text-secondary);
    }
}
